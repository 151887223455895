import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import CodeExample from "../components/home/CodeExample"
import { LandingPageFeatures } from "../components/landingPage/LandingPageFeatures"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "30px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
}))

const JavaPropertiesTechnologyPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)
  // const [isSecondTranslated, setSecondTranslated] = useState(false);
  const image = data

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Translate Java Property Files"
          subtitle="Works with Java Spring and Java EE"
        />
      </HeroSection>

      <Container maxWidth="lg" className={classes.mainArea}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Start localizing your Java project by translating your properties
              locale files with machine translation..
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Use Simpleen to translate Java projects dependency free with the
              online translator. Simply copy/paste or upload/download your utf-8
              java property file and get instant results.
              <br />
              <br />
              To continuously translate your project changes use the Simpleen
              CLI to find locales for translation and save the translation
              results directly in your project. Simpleen manages the correct
              handling of the properties format and the interpolation of
              variables.
              <br />
              <br />
              Save time in your multilingual projects by using Simpleen.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <GatsbyImage
              image={data.javaproperties.childImageSharp.gatsbyImageData}
              alt="Java Spring Boot Logo"
              style={{ float: "right", marginRight: "40px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <CodeExample
              codeFirst={data.snippetExample}
              codeSecond={data.snippetExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="de"
              isTranslated={isFirstTranslated}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert" : "Translate Example"}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <CompatibleLibraries currentPage="java Properties" />

      <LandingPageFeatures source="Signup ButtonMarkdown" />

      <Seo
        general={{
          title: "Translate Java Properties Files",
          description:
            "Localize java properties files with machine translation continuously",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const docuLPQuery = graphql`
  query JavaPropertiesLPQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    javaproperties: file(relativePath: { eq: "logos/spring.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          quality: 100
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    snippetExample: markdownRemark(
      frontmatter: { slug: { eq: "/javaCodeLP2" } }
    ) {
      html
    }
    snippetExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/javaCodeLP2Translated" } }
    ) {
      html
    }
  }
`

export default JavaPropertiesTechnologyPage
